export const BASE_URL = "https://bookshow.online/api/";  // Staging URL
// export const BASE_URL = "http://localhost:8003/api/";  // Local URL

export const NAV_LINKS = [
    { text: 'Events', to: '/events' },
    { text: 'About us', to: '/about-us' },
    { text: 'Contact', to: '/contact' },
];

// FOOTER LINK MOCK DATA
export const FOOTER_LINK = [
    { id: 1, text: "Public Events", to: "/events" },
    { id: 2, text: "Privacy Policy", to: '/about-us' },
    { id: 3, text: "About us", to: '/contact' },
    { id: 4, text: "Contact", to: '/contact' },
];

export const LOCATIONS = ['Chandigarh', 'Bengaluru', 'Delhi', 'Hisar'];

// UPCOMING/PUBLIC EVENTS MOCK DATA
export const EVENTS_DATA = [
    {
        id: 1,
        imageUrl: "/mehfil_sartaj.png",
        category: "Music",
        title: "Wonder Girls 2025 | Bangalore",
        date: "Feb 8",
        isoDate: "2025-02-08",
        time: "03PM - 09PM",
        location: "Sri Kanteerava Outdoor Stadium, Bengaluru",
        price: 1599,
    },
    {
        id: 2,
        imageUrl: "/mehfil_sartaj.png",
        category: "Music",
        title: "JYJ 2025 JYJ Worldwide | Mumbai",
        date: "Feb 15",
        isoDate: "2025-02-15",
        time: "06PM - 11PM",
        location: "Wankhede Stadium, Mumbai",
        price: 1999,
    },
    {
        id: 3,
        imageUrl: "/mehfil_sartaj.png",
        category: "Theater",
        title: "Super Junior SM Town | New Delhi",
        date: "Feb 15",
        isoDate: "2025-02-15",
        time: "06PM - 11PM",
        location: "Jawaharlal Nehru Stadium, New Delhi",
        price: 1699,
    },
    {
        id: 4,
        imageUrl: "/mehfil_sartaj.png",
        category: "Music",
        title: "Royal Stag Boombox | Gurugram",
        date: "Feb 8",
        isoDate: "2025-02-08",
        time: "03PM - 06PM",
        location: "Huda Ground, Gurugram",
        price: 999,
    },
    {
        id: 5,
        imageUrl: "/mehfil_sartaj.png",
        category: "Workshop",
        title: "The Art Of Intimacy Workshop | Delhi",
        date: "Mar 08",
        isoDate: "2025-03-08",
        time: "10AM - 06PM",
        location: "Zorba The Buddha, Delhi",
        price: 5000,
    },
    {
        id: 6,
        imageUrl: "/mehfil_sartaj.png",
        category: "Workshop",
        title: "AR VR Workshop | Chandigarh",
        date: "Feb 08 - Feb 09",
        isoDate: "2025-02-08",
        time: "12:30PM - 6:30PM",
        location: "STEAM Varsity Makerspace, Chandigarh",
        price: 2499,
    },
];

// TERMS & CONDITIONS
export const TERMS_AND_CONDITIONS = [
    {
        id: 1,
        label: "Carry a valid government-issued photo ID proof.",
    },
    {
        id: 2,
        label: "Entry will be allowed only for 21 years and above."
    },
    {
        id: 3,
        label: "One time entry per person, re-entry not allowed."
    },
    {
        id: 4,
        label: "No refund on a purchased ticket is possible, even in case of any rescheduling."
    },
    {
        id: 5,
        label: "Organizers, associate partners, and sponsors assume no responsibility in terms of artist no-show for any reason."
    },
    {
        id: 6,
        label: "The artists performing in your city are mentioned in the event banner in words."
    },
    {
        id: 7,
        label: "Organizers reserve the right to perform security checks including frisking at all entry points. In-case anyone is found violating rules, they will be denied entry despite holding a valid ticket WITHOUT any refund."
    },
    {
        id: 8,
        label: "Consumption and sale of illegal substances is strictly prohibited. Persons in an inebriated state will be denied entry."
    }
];

// TICKETS CATEGORIES
export const TICKET_TYPES = [
    {
        type: 'General Admission',
        price: 1599,
        description: 'Entry without reserved seating (first come, first served for spots)'
    },
    {
        type: 'VIP Tickets',
        price: 2499,
        description: "VIP's reserved seating, early entry and VIP charges"
    },
    {
        type: 'Reserved Seating',
        price: 2999,
        description: 'Specific seats are assigned in advance'
    }
];

// LIST_YOUR_EVENTS
export const EVENT_INFO = [
    {
        id: 1,
        title: "Quick and easy event creation",
        label: "Create your event page, it is easy and customizable."
    },
    {
        id: 2,
        title: "Start selling tickets within minutes",
        label: "Link your bank account, verify identity details and start selling instantly."
    },
    {
        id: 3,
        title: "Simple Integration with your website",
        label: "Easy to start selling tickets directly from your website and facebook without developers."
    },
];

// Email Validation
export const isEmailValid = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

// Phone Number Validation
export const isPhoneNumberValid = (phoneNumber: string): boolean => {
    // const phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
    // return phoneRegex.test(phoneNumber);

    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(phoneNumber);
};

export const formatDateAndTime = (dateString: Date, type: string) => {
    const date = new Date(dateString);

    if (type === "month") {
        // Return the month and day in the format "Feb 8"
        return date.toLocaleString('en-US', { month: 'short', day: 'numeric' });
    } else if (type === "time") {
        // Return the time in 12-hour format with AM/PM (e.g., "09:00 AM")
        return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    } else {
        // If no valid type is passed, return the full date
        return date.toLocaleString('en-US');
    }
};
